import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { CSVLink } from 'react-csv/lib';
import {
  ExcelExport,
  ExcelExportColumn,
} from '@progress/kendo-react-excel-export';
import { useDispatch } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import PageBase from 'components/PageBase';
import {
  makeStyles,
  Button,
  MenuItem,
  IconButton,
  Tooltip,
  Select,
  Grid,
  AppBar,
  Typography,
  Chip
} from '@material-ui/core';
import { store } from 'react-notifications-component';
import useRouter from 'utils/useRouter';
import {
  profileURL,
  ROLE_CLIENT,
  EXPORT_MODE_EXCEL,
  EXPORT_MODE_CSV,
} from 'config';
import * as API from 'services/api';
import {
  get10LastLetters,
  getHHMMDDMMYYYYFromTimeStamp,
} from 'utils/validations';
import { setLoading } from 'redux/actions/app';
import InfiniteScroll from 'react-infinite-scroller';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import RemoveUserModal from 'components/Modals/RemoveUserModal';
import CustomTable from 'components/CustomTable'
import SearchBar from 'components/CustomTable/searchbar'
import Filters from 'components/CustomTable/filters'
import { includesSearchWord } from 'utils'
import { TabPanel, StyledTabs, StyledTab } from 'components/CustomTab'
import useHomeContext from 'layouts/HomeLayout/context'
import ImportUsersModal from './ImportModal';
import Tabs from './tab';
import _ from 'lodash';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  container: {
    marginBottom: 15,
  },
  title: {
    paddingTop: 20,
    paddingBottom: 20,
    fontSize: 15,
    color: theme.palette.text.primary,
    fontWeight: 500,
  },
  avatar: {
    margin: 5,
    cursor: 'pointer',
  },
  icon: {
    fill: 'inherit !important'
  },
  password: {
    minWidth: 80,
  },
  actions: {
    minWidth: 80,
  },
  actionButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  csv: {
    display: 'none',
  },
  excel: {
    marginRight: 8,
    backgroundColor: '#040f40',
    padding: 10,
    color: '#fff',
    borderRadius: 15,
    '&:hover': {
      backgroundColor: '#00B0EC',
    },
  },
  spinner: {
    display: 'block',
    margin: 'auto',
    marginTop: 20,
    marginBottom: 20,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  rightdom: {
    marginLeft: 'auto'
  },
  table: {
    marginLeft: theme.spacing(5),
    marginRight: theme.spacing(5)
  },
  appbar: {
    boxShadow: 'none',
    padding: theme.spacing(0, 5),
    backgroundColor: theme.palette.background.default,
  },
  tabsContainer: {
    color: theme.palette.app.primary
  },
  tabContainer: {
    maxWidth: 'unset'
  }
}));

const PERPAGE = 6;
const EXPORT_MODE_ETABLISSEMENTS_EXCEL = "EXPORT_MODE_ETABLISSEMENTS_EXCEL";
const EXPORT_MODE_ETABLISSEMENTS_CSV = "EXPORT_MODE_ETABLISSEMENTS_CSV";
const PERPAGE_EXPORT = 10000;
const Clients = ({ intl: { formatMessage } }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { hasPermission } = useHomeContext();
  const [data, setData] = useState([]);
  const [users, setUsers] = useState([]);
  const [comptesels, setComptesels] = useState([]);
  const [usersLoading, setUsersLoading] = useState(true);
  const [allUsersLoaded, setAllUsersLoaded] = useState(false);
  const csvLink = useRef();
  const excelExporter = useRef();
  const [page, setPage] = useState(0);

  const csvLink1 = useRef();
  const excelExporter1 = useRef();

  const router = useRouter();

  const [openFilter, setOpenFilter] = useState(false)
  const [filters, setFilters] = useState({})
  const [keyword, setKeyword] = useState('')
  const [counts, setCounts] = useState()
  const [importUsersModalVisible, setImportUsersModalVisible] = useState(false);

  const [removeModalInfo, setRemoveModalInfo] = useState({
    open: false,
    id: null,
  });

  const handleCloseRemoveModal = confirmed => {
    if (confirmed) {
      deleteUser(removeModalInfo.id);
    }
    setRemoveModalInfo({
      open: false,
      id: null,
    });
  };
  useEffect(() => {
    loadUsers(true);
  }, [page]);
  useEffect(() => {
    if (page == 0) {
      loadUsers(true);
    }
    else setPage(0);
  }, [keyword]);

  const loadUsers = (refresh) => {
    //dispatch(setLoading(true));
    setUsersLoading(true);
    Promise.all([
      API.listUsers({
        page: page + 1,
        perPage: PERPAGE,
        role: ROLE_CLIENT,
        ...keyword && keyword.length > 0 ? { keyword } : {}
      }),
      API.listCompteSels({
        offset: page,
        limit: PERPAGE,
        typologieDuClient: ["National", "Local"],
        ...keyword && keyword.length > 0 ? { keyword } : {}
      })
    ])
      .then(([res1, res2]) => {
        
        let newUsers = page == 0 ? [] : users;
        if (res1.code === 200) {
          newUsers = [...newUsers, ...res1.users];
        }
        if (res2.code === 200 || res2.code === 206) {
          newUsers = [...newUsers, ...res2.data.map(el => ({
            ...el,
            email: _.get(el, "destinataireLivrable.email", ""),
            firstName: _.get(el, "destinataireLivrable.prenom", ""),
            lastName: _.get(el, "destinataireLivrable.nom", ""),
            position: _.get(el, "destinataireLivrable.fonction", ""),
            zipCode: _.get(el, "relationCompte.adresse.codePostal", ""),
            address: _.get(el, "relationCompte.adresse.adresse4", ""),
            company: _.get(el, "relationCompte.raisonSociale", ""),
            country: _.get(el, "relationCompte.adresse.libellePays", ""),
            siret: _.get(el, "relationCompte.numeroIdentificationLocal", ""),
            domain: "duns.com",
            compteSEL: el.idCompteSel,
            numLogin: 0
          }))];
        }
        
        setUsers(newUsers)
        if (res1.users.length < PERPAGE && res2.data.length < PERPAGE) {
          setAllUsersLoaded(true)
        }
        dispatch(setLoading(false));
        setUsersLoading(false);
      }).catch(err => {
        dispatch(setLoading(false));
        setUsersLoading(false);
      });
  };

  const loadMore = () => {
    if (allUsersLoaded || usersLoading) {
      return;
    }
    setPage(prev => prev + 1);
  };

  const exportExcel = () => {
    setData([]);
    loadExportData(1, EXPORT_MODE_EXCEL);
  };

  const exportCSV = () => {
    setData([]);
    loadExportData(1, EXPORT_MODE_CSV);
  };

  const exportExcel1 = () => {
    setData([]);
    loadExportData(1, EXPORT_MODE_ETABLISSEMENTS_EXCEL);
  };

  const exportCSV1 = () => {
    setData([]);
    loadExportData(1, EXPORT_MODE_ETABLISSEMENTS_CSV);
  };

  const exportData = (exportMode) => {
    if (exportMode === EXPORT_MODE_EXCEL) {
      excelExporter.current.save();
    } else if (exportMode === EXPORT_MODE_ETABLISSEMENTS_EXCEL) {
      excelExporter1.current.save();
    } else if (exportMode === EXPORT_MODE_CSV) {
      csvLink.current.link.click();
    } else {
      csvLink1.current.link.click();
    }
  };

  const loadExportData = (page, exportMode) => {
    dispatch(setLoading(true));
    API.listUsers({ page, perPage: PERPAGE_EXPORT, role: ROLE_CLIENT, ...keyword && keyword.length > 0 ? { keyword } : {} }).then(res => {
      if (res.code === 200) {
        let newUsers = res.users;
        if (exportMode === EXPORT_MODE_ETABLISSEMENTS_EXCEL || exportMode === EXPORT_MODE_ETABLISSEMENTS_CSV) {
          newUsers = [];
          res.users.map(el => {
            if (el.establishments && el.establishments.length > 0) {
              el.establishments.map(es => {
                newUsers.push({
                  ...el,
                  ...es,
                  id: el.id
                })
              })
            }
          })
        }
        setData(prev => [...prev, ...newUsers]);
        if (res.users.length < PERPAGE_EXPORT) {
          dispatch(setLoading(false));
          exportData(exportMode);
        } else {
          loadExportData(page + 1, exportMode);
        }
      }
    });
  };
  const onUploadUsers = (file, type, clientId) => {
    dispatch(setLoading(true));
    const formData = new FormData();
    formData.append('type', type);
    formData.append('file', file);
    formData.append('clientId', clientId);
    API.importUsers(formData)
      .then(res => {
        dispatch(setLoading(false));
        if (res.code === 200) {
          toggleUsersImportModal();
          window.location.reload();
        } else {
          store.addNotification({
            message: res.message,
            type: 'danger',
            container: 'top-right',
            animationIn: ['animated', 'fadeIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: {
              duration: 5000,
              onScreen: true,
            },
          });
        }
      })
      .catch(err => {
        dispatch(setLoading(false));
      });
  };

  const onDeleteUser = id => {
    setRemoveModalInfo({
      open: true,
      id,
    });
  };

  const deleteUser = id => {
    dispatch(setLoading(true));

    API.deleteUser(id)
      .then(res => {
        dispatch(setLoading(false));
        if (res.code === 200) {
          store.addNotification({
            message: 'User is removed successfully',
            type: 'success',
            container: 'top-right',
            animationIn: ['animated', 'fadeIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: {
              duration: 5000,
              onScreen: true,
            },
          });
          setUsers(users => users.filter(e => e.id !== id));
        }
      })
      .catch(err => {
        dispatch(setLoading(false));
      });
  };

  const onAddClient = () => {
    router.history.push('/clients/create');
  };

  const excelData = data.map(user => ({
    id: get10LastLetters(user.id),
    avatar: user.picture === undefined ? '' : profileURL + user.picture,
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    company: user.company,
    zipCode: user.zipCode,
    status: user.status,
    numLogin: parseInt(user.numLogin || 0) + 1,
    lastLogin: user.lastLogin ? getHHMMDDMMYYYYFromTimeStamp(user.lastLogin) : "",
    createdAt: getHHMMDDMMYYYYFromTimeStamp(user.createdAt),

    city: user.city,
    address: user.address,
    country: user.country,
    position: user.position,
    siret: user.siret,
    name: user.name,
    pays: user.pays,
    compteSEL: user.compteSEL
  }));

  const headers = [
    { label: formatMessage({ id: 'no' }), key: 'id' },
    { label: formatMessage({ id: 'company' }), key: 'company' },
    { label: formatMessage({ id: 'siret' }), key: 'siret' },
    { label: "idCompteSel", key: 'compteSEL' },
    { label: formatMessage({ id: 'first_name' }), key: 'firstName' },
    { label: formatMessage({ id: 'last_name' }), key: 'lastName' },
    { label: formatMessage({ id: 'email' }), key: 'email' },
    { label: formatMessage({ id: 'address' }), key: 'address' },
    { label: formatMessage({ id: 'zipcode' }), key: 'zipCode' },
    { label: formatMessage({ id: 'city' }), key: 'city' },
    { label: formatMessage({ id: 'country' }), key: 'country' },
    { label: formatMessage({ id: 'position' }), key: 'position' },
    { label: formatMessage({ id: 'status' }), key: 'status' },
    { label: formatMessage({ id: 'numLogin' }), key: 'numLogin' },
    { label: formatMessage({ id: 'lastLogin' }), key: 'lastLogin' },
    { label: formatMessage({ id: 'created_at' }), key: 'createdAt' },
  ];

  const headers1 = [
    { label: formatMessage({ id: 'no' }), key: 'id' },
    { label: formatMessage({ id: 'company' }), key: 'company' },
    { label: formatMessage({ id: 'home.clients.establishments.name' }), key: 'name' },
    { label: formatMessage({ id: 'home.clients.establishments.siret' }), key: 'siret' },
    { label: formatMessage({ id: 'home.clients.establishments.email' }), key: 'email' },
    { label: formatMessage({ id: 'home.clients.establishments.address' }), key: 'address' },
    { label: formatMessage({ id: 'home.clients.establishments.zipCode' }), key: 'zipCode' },
    { label: formatMessage({ id: 'home.clients.establishments.city' }), key: 'city' },
    { label: formatMessage({ id: 'home.clients.establishments.pays' }), key: 'pays' }
  ];

  const tableColumns = [
    {
      name: 'id',
      label: formatMessage({
        id: 'id',
      }),
      options: {
        filter: false,
        sort: true,
        customBodyRender: value => get10LastLetters(value),
      },
    },
    {
      name: 'grantComptes',
      label: "Type",
      options: {
        filter: true,
        sort: true,
        customBodyRender: value => <Chip color={value ? 'primary' : 'secondary'} variant='outlined' label={value ? "Grands Comptes" : "Client"} size="small" />
      },
    },
    {
      name: 'domain',
      label: "OneData",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return value == "duns.com" ? <Chip color={'primary'} variant='outlined' label={"OneData"} size="small" /> : ""
        }
      },
    },
    {
      name: 'company',
      label: formatMessage({
        id: 'company',
      }),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'siret',
      label: formatMessage({
        id: 'siret',
      }),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'firstName',
      label: formatMessage({ id: 'first_name' }),
      options: { filter: false, sort: true },
    },
    {
      name: 'lastName',
      label: formatMessage({ id: 'last_name' }),
      options: { filter: false, sort: true },
    },
    {
      name: 'email',
      label: formatMessage({ id: 'email' }),
      options: { filter: false, sort: true },
    },
    {
      name: 'status',
      label: formatMessage({ id: 'status' }),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => (
          <FormattedMessage id={`home.users.${(value || "registered").toLowerCase()}`} />
        ),
      },
    },
    {
      name: 'numLogin',
      label: formatMessage({ id: 'numLogin' }),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => (
          <Typography style={{ textAlign: 'center' }}>{parseInt(value || 0) + 1}</Typography>
        ),
      },
    },
    {
      name: 'lastLogin',
      label: formatMessage({ id: 'lastLogin' }),
      options: {
        filter: true,
        sort: true,
        customBodyRender: value => value ? getHHMMDDMMYYYYFromTimeStamp(value) : "",
      },
    },
    {
      name: 'createdAt',
      label: formatMessage({
        id: 'created_at',
      }),
      options: {
        filter: false,
        sort: true,
        customBodyRender: value => getHHMMDDMMYYYYFromTimeStamp(value),
      },
    },
    {
      name: 'actions',
      label: formatMessage({
        id: 'actions',
      }),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const record = users.find(item => item.id == tableMeta.rowData[0])
          return (
            record.domain != "duns.com" &&
            <React.Fragment>
              <Tooltip title={formatMessage({ id: 'tooltip.delete' })}>
                <IconButton onClick={() => {
                  onDeleteUser(tableMeta.rowData[0]); // id
                }}>
                  <DeleteIcon className={classes.icon} />
                </IconButton>
              </Tooltip>
              <Tooltip title={formatMessage({ id: 'edit' })}>
                <IconButton onClick={() => {
                  router.history.push(`/client`, { id: tableMeta.rowData[0] });
                }}>
                  <EditIcon className={classes.icon} />
                </IconButton>
              </Tooltip>
            </React.Fragment>
          )
        }
      },
    },
  ];

  const toggleUsersImportModal = (refresh) => {
    if (refresh) {
      loadUsers(true);
    }
    setImportUsersModalVisible(
      importUsersModalVisible => !importUsersModalVisible,
    );
  };

  let datasource = users;

  if (!Object.values(filters).find(item => !item)) {
    datasource = datasource.filter(item => {
      const fields = Object.keys(filters)
      const inValid = !!fields.find(el => filters[el] && item[el] != filters[el])
      return !inValid
    })
  }
  if (!hasPermission("clients")) return <Typography variant='h4' style={{ textAlign: 'center', marginTop: "30vh" }}><strong>Permission Denied!</strong></Typography>
  return (
    <PageBase title={formatMessage({ id: 'navbar.entreprisesfrance' })}
      rightComponent={<div className={classes.rightdom}>
        <Button
          onClick={toggleUsersImportModal}
          classes={{ root: classes.excel }}
        >
          <FormattedMessage id="home.client.import" />
        </Button>
        <Button onClick={onAddClient} classes={{ root: classes.excel }}>
          <FormattedMessage id="home.client.add_client" />
        </Button>
        <Button onClick={exportExcel} classes={{ root: classes.excel }}>
          <FormattedMessage id="export_xls" />
        </Button>
        <Button onClick={exportCSV} classes={{ root: classes.excel }}>
          <FormattedMessage id="export_csv" />
        </Button>
        <CSVLink
          data={excelData}
          headers={headers}
          className={classes.csv}
          filename="clients.csv"
          ref={csvLink}
        >
          CSV
        </CSVLink>


        <Button onClick={exportExcel1} classes={{ root: classes.excel }}>
          <FormattedMessage id="export_establishments_xls" />
        </Button>
        <Button onClick={exportCSV1} classes={{ root: classes.excel }}>
          <FormattedMessage id="export_establishments_csv" />
        </Button>
        <CSVLink
          data={excelData}
          headers={headers1}
          className={classes.csv}
          filename="establishments.csv"
          ref={csvLink1}
        >
          CSV
        </CSVLink>
      </div>}
    >
      <div>
        <Tabs value={"france"} />
        <Grid container >
          <Grid item sm={openFilter ? 9 : 12} style={{ padding: '0px !important' }}>
            <SearchBar
              openFilter={openFilter}
              setOpenFilter={setOpenFilter}
              keyword={keyword}
              setKeyword={setKeyword}
              counts={counts}
            />
            <InfiniteScroll
              pageStart={0}
              loadMore={loadMore}
              hasMore={!allUsersLoaded}
              className={classes.table}
            >
              <CustomTable
                columns={tableColumns}
                data={datasource}
                options={{
                  pagination: false,
                  print: false,
                  download: false,
                  selectableRows: 'none',
                  searchable: false,
                  viewColumns: false,
                  elevation: 0,
                  filter: false,
                  search: false,
                  textLabels: {
                    body: {
                      noMatch: formatMessage({
                        id: 'muitable.placeholder.nomatch_records',
                      }),
                    },
                    filter: {
                      all: formatMessage({ id: 'all' }),
                      title: formatMessage({ id: 'filters' }),
                      reset: formatMessage({ id: 'reset' }),
                    },
                  },
                }}
              />
            </InfiniteScroll>
          </Grid>
          {
            openFilter &&
            <Filters
              setOpenFilter={setOpenFilter}
              setOnFilter={setFilters}
              columns={tableColumns}
              data={users}
            />
          }
        </Grid>
        <br />
        <br />
        <br />
        <RemoveUserModal
          open={removeModalInfo.open}
          description={formatMessage({
            id: 'comments.please_confirm_again_to_delete_user',
          })}
          onCloseModal={handleCloseRemoveModal}
        />
        <ImportUsersModal
          addFree
          visible={importUsersModalVisible}
          toggleModal={toggleUsersImportModal}
          onUpload={onUploadUsers}
        />
        <ExcelExport
          data={excelData}
          fileName="clients.xlsx"
          ref={excelExporter}
        >
          {
            headers.map(el => <ExcelExportColumn
              key={el.key}
              field={el.key}
              title={el.label}
              width={100}
              cellOptions={{ fontSize: 12 }}
              headerCellOptions={{ fontSize: 14 }}
            />)
          }
        </ExcelExport>
        <ExcelExport
          data={excelData}
          fileName="establishments.xlsx"
          ref={excelExporter1}
        >
          {
            headers1.map(el => <ExcelExportColumn
              key={el.key}
              field={el.key}
              title={el.label}
              width={100}
              cellOptions={{ fontSize: 12 }}
              headerCellOptions={{ fontSize: 14 }}
            />)
          }
        </ExcelExport>
      </div>
    </PageBase>
  );
};

Clients.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }),
};

export default injectIntl(Clients);
