import React, { useState, useEffect } from 'react';
import clsx from 'classnames';
import { injectIntl, FormattedMessage } from 'react-intl';
import { makeStyles, withStyles, Typography, Grid, TextField, Button, Divider, Select, MenuItem, Checkbox, IconButton } from '@material-ui/core';
import { ReactComponent as IconFilter } from 'assets/svgs/product_filter.svg';

const useStyles = makeStyles(theme => ({
    container: {
        padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
        backgroundColor: 'white',
        borderRadius: 15,
        margin: theme.spacing(3, 5)
    },
    label: {
        fontSize: 12,
        fontWeight: 'bold',
        whiteSpace: 'nowrap',
        marginRight: 8,
    },
    dropIcon: {
        fill: theme.palette.app.primary
    },
    applybtn: {
        borderRadius: 12,
        backgroundColor: theme.palette.app.primary,
        color: 'white',
        "&:hover": {
            backgroundColor: theme.palette.app.fifth,
        },
        marginRight: theme.spacing(2)
    },
    filterIcon: {
        width: 24,
        height: 24
    },
    gridCell: {
        display: 'flex',
        alignItems: 'center'
    },
    divider: {
        margin: theme.spacing(0, 1),
        height: 48,
    },
    filterBtn: {
        height: 38,
        marginLeft: theme.spacing(2),
        padding: theme.spacing(0.5, 2.5)
    },
    formControl: {
        minWidth: 300,
    },
    input: {
        '&:before': {
            borderBottomStyle: 'dashed',
        },
        '&:after': {
            borderBottomStyle: 'dashed',
        }
    }
}));

const SearchBar = ({ intl: { formatMessage },
    prefix,
    keyword,
    setKeyword,
    openFilter,
    setOpenFilter,
    containerStyle,
    rightComponent,
    placeholder,
    counts,
    hasFilter
}) => {
    const classes = useStyles();

    return (
        <div className={clsx(classes.container, containerStyle)}>
            <Grid
                container
                direction="row"
                alignItems="center"
                justify="space-between"
                spacing={1}
            >
                {
                    prefix &&
                    <Grid item xs={4}>
                        {prefix}
                    </Grid>
                }
                <Grid item className={classes.gridCell}>
                    <Typography className={classes.label}>{counts ? <span style={{ marginRight: 32 }}>{`Rows: ${counts}`}</span> : ""}{placeholder ? placeholder : <FormattedMessage id="table.filter.search" />}</Typography>
                    <TextField fullWidth label="" className={classes.formControl} InputProps={{ className: classes.input }}
                        value={keyword}
                        onChange={e => {
                            e.persist()
                            setKeyword(e.target.value)
                        }}
                    />
                </Grid>
                {rightComponent}
                <Grid item >
                    <Grid container justify="space-around" alignItems="center">
                        {
                            !openFilter && hasFilter &&
                            <Button className={classes.filterBtn} startIcon={<IconFilter className={classes.filterIcon} />} variant="outlined" size="small"
                                onClick={() => setOpenFilter(true)}
                            ><FormattedMessage id="table.filter.button" /></Button>
                        }
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default injectIntl(SearchBar);
